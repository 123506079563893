import { ClassNames } from '@rocketmakers/armstrong-edge';
import * as React from 'react';
import { Category as CategoryType, getFriendlyCategoryName } from '../typings/data';

import './category.scss';

interface IDefinitionProps {
  type: CategoryType;
  description: string;
  even: boolean;
}

export const Category: React.FC<IDefinitionProps> = ({ type, description, even }) => {
  return (
    <label key={type} className="category" data-is-even={even}>
      <div className={ClassNames.concat('icon-wrapper', type)}>
        <div className="icon-inner">
          <img alt={type} src={`/icons/${type}.svg`} />
        </div>
      </div>
      <div>
        <div className="">{getFriendlyCategoryName(type)}</div>
        <div>{description}</div>
      </div>
    </label>
  );
};
