import { ClassNames, SwitchInput } from '@rocketmakers/armstrong-edge';
import * as React from 'react';
import { SupportCategories, TechForGoodCategories } from '../typings/data';
import Shell from '../layout/shell';
import { AppContext } from '../context/appProvider';
import Footer from '../components/footer';
import { Category } from '../components/category';

import './categories.scss';

const Categories: React.FC = () => {
  const { filterMode, setFilterMode } = React.useContext(AppContext);

  return (
    <>
      <Shell>
        <div className="footer-categories">
          <div className="container">
            <div className={ClassNames.concat('card-filter-toggle', filterMode)}>
              <label>Tech for Good Companies</label>
              <SwitchInput
                checked={filterMode === 'support'}
                onChange={(e) => setFilterMode(filterMode === 'tech-for-good' ? 'support' : 'tech-for-good')}
              />
              <label>The Support Community</label>
            </div>
            <div className="categories-container">
              {(filterMode === 'tech-for-good' ? TechForGoodCategories : SupportCategories)
                .slice()
                .sort()
                .map((c, i) => (
                  <Category type={c.name} description={c.description} even={!(i % 2)} />
                ))}
            </div>
          </div>
          <Footer isCategories />
        </div>
      </Shell>
    </>
  );
};

export default Categories;
